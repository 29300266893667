<template>
    <Transition>
        <div
            v-if="catalogWidgetModel"
            ref="container"
            :style="{'height': height}"
            class="catalog-header-widget container"
            @mouseleave="mouseLeaveHandler"
        >
            <QCard
                class="catalog-header-widget__content">
                <template v-if="catalogStore.loading">
                    <div class="catalog-header-widget__left-menu">
                        <div v-for="index in 20"
                             :key="index"
                             class="catalog-header-widget__left-menu-item q-mb-sm">
                            <QSkeleton
                                style="height: 36px"
                                type="QInput"/>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="catalog-header-widget__left-menu">
                        <div v-for="category in catalogStore.list"
                             :key="category.id"
                             class="catalog-header-widget__left-menu-item">
                            <QBtn
                                :class="['full-width', {'bg-grey-3': category.id === selectedCategoryId }]"
                                :title="category.name"
                                align="left"
                                no-caps
                                unelevated
                                @click="toCategory(category)"
                                @mouseenter="selectCategory(category.id)">
                                <span class="flex items-center no-wrap full-width">
                                    <img v-if="category.icon"
                                         :src="category.icon"
                                         class="q-mr-sm"
                                         style="width: 20px; height: 20px">

                                    <span
                                        class="catalog-header-widget__left-menu-item-name q-mr-sm">{{
                                        category.name
                                    }}</span>
                                    <QIcon v-if="category.children.length"
                                           :color="category.id === selectedCategoryId ? 'grey-7' : 'grey-4'"
                                           class="q-ml-auto"
                                           name="fas fa-chevron-right"
                                           size="xs"/>
                                </span>
                            </QBtn>
                        </div>
                    </div>

                    <div class="catalog-header-widget__main-container">
                        <Transition name="fade">
                            <CatalogHeaderWidgetMain
                                v-if="selectedCategory && selectedCategoryChildren.length"
                                :key="selectedCategory.id"
                                :children="selectedCategoryChildren"
                                :parent="selectedCategory"/>
                        </Transition>
                    </div>
                </template>
            </QCard>
        </div>
    </Transition>
</template>

<script>
import CatalogHeaderWidgetMain from '@/components/catalog/CatalogHeaderWidgetMain'

import {useCatalogStore} from '@/stores/catalog'

import {computed, onMounted, onUnmounted, ref, watch} from 'vue'
import {useRouter} from 'vue-router'

export default {
	name: 'CatalogHeaderWidget',

	components: {
		CatalogHeaderWidgetMain
	},

	props: {
		height: {
			type: String,
			default: '500px'
		}
	},

	setup() {
		const catalogStore = useCatalogStore()

		const selectedCategoryId = ref(catalogStore.list[0]?.id)

		const selectedCategory = computed(() => {
			const selectedCategory = catalogStore.list.find(el => el.id === selectedCategoryId.value)

			return selectedCategory || catalogStore.list[0] || []
		})

		const selectedCategoryChildren = computed(() => {
			return selectedCategory.value ? selectedCategory.value.children : []
		})

		const catalogWidgetModel = computed({
			get() {
				return catalogStore.headerWidgetModel
			},

			set(value) {
				catalogStore.toggleHeaderWidgetModel(value)
			}
		})

		watch(catalogWidgetModel, newValue => {
			if (newValue && !catalogStore.list.length) {
				catalogStore.get()
			}
		})

		const mouseLeaveHandler = () => {
			catalogWidgetModel.value = false
		}

		const selectCategory = id => {
			selectedCategoryId.value = id
		}

		const router = useRouter()
		const toCategory = category => {
			router.push({path: `/catalog/${category.friendlyUrl}`})
			catalogWidgetModel.value = false
		}

		const container = ref(null)
		const onClickHandler = event => {
			if (container.value && event.target !== container.value && !container.value.contains(event.target)) {
				catalogWidgetModel.value = false
			}
		}

		onMounted(() => {
			document.addEventListener('click', onClickHandler)
		})

		onUnmounted(() => {
			document.removeEventListener('click', onClickHandler)
		})

		return {
			catalogStore,

			selectedCategory,
			selectedCategoryChildren,
			selectedCategoryId,

			mouseLeaveHandler,
			selectCategory,

			toCategory,

			catalogWidgetModel,

			container
		}
	}
}
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: transform 0.15s ease, opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translate(0, 20px);
  opacity: 0;
}

.catalog-header-widget {
  top: calc(100% - 8px);
  padding-top: 16px;
  position: absolute;
  width: 100%;
  z-index: 1;
  color: $dark;
}

.catalog-header-widget__content {
  height: 100%;
  width: 100%;
  background-color: $grey-1;
  padding: 16px;
  display: flex;
  gap: 16px;
}

.catalog-header-widget__left-menu {
  width: 320px;
  flex: none;
  overflow: auto;
  padding-right: 16px;
}

.catalog-header-widget__left-menu::-webkit-scrollbar, .catalog-header-widget__main::-webkit-scrollbar {
  width: 5px;
}

.catalog-header-widget__left-menu::-webkit-scrollbar-track, .catalog-header-widget__main::-webkit-scrollbar-track {
  background-color: $grey-3;
  border: 1px solid white;
  border-radius: 3px;
}

.catalog-header-widget__left-menu::-webkit-scrollbar-thumb, .catalog-header-widget__main::-webkit-scrollbar-thumb {
  background-color: $grey-6;
  border-radius: 3px;
}

.catalog-header-widget__left-menu-item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.catalog-header-widget__main-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: .35s ease all;
}

.fade-enter-from,
.fade-leave-to {
  transform: scale(0.95);
  opacity: 0;
}

.fade-leave-active {
  position: absolute;
}
</style>
