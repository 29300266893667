const _debounceList = new Map()

const remove = key => {
	if (_debounceList.has(key)) {
		clearTimeout(_debounceList.get(key))
		_debounceList.delete(key)
	}
}
export const debounce = (fn, ms = 500, uniqueKey = null) => {
	const key = fn || uniqueKey

	remove(key)

	const timeout = setTimeout(() => {
		fn()
		remove(key)
	}, ms)

	_debounceList.set(key, timeout)
}