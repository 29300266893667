<template>
    <template v-if="loading">
        <QSkeleton v-bind="skeletonLoader"/>
    </template>
    <template v-else>
        <div class="text-center"
             v-if="isFree">
            Бесплатно
        </div>
        <div
            class="flex text-center"
            v-else-if="whole != '0' && !hidePrice"
        >
            <span class="text-weight-bolder">
                <template v-if="approximately">от </template>
                <template v-if="limit">до </template>
                {{ whole }}
            </span>
            <small
                v-if="(remains > 0)"
                class="text-weight-bold text-xs-em"
            >
                <sup>{{ remains < 10 ? '0' + remains : remains }}</sup>
            </small>
            <span class="text-weight-bold">₽</span>
        </div>
        <div class="text-center"
             v-else>
            <span class="text-weight-bolder"> Цена по запросу </span>
        </div>
    </template>
</template>

<script>
import {getPrice, sumTotal} from '@/use/price'
import {computed} from 'vue'

export default {
	props: {
		priceWhole: Number,
		priceRemains: {
			type: Number,
			default: 0
		},
		limit: {
			type: Boolean,
			default: false
		},
		priceTotal: Number,
		approximately: {
			type: Boolean,
			default: false
		},
		hidePrice: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		skeletonLoader: {
			type: Object,
			default: () => ({
				height: '18px',
				width: '45px'
			})
		},
		isFree: {
			type: Boolean,
			default: false
		}
	},

	setup(props) {
		const whole = computed(() => getPrice(sumTotal(props.priceWhole, props.priceRemains, props.priceTotal || 1).whole))
		const remains = computed(() => sumTotal(props.priceWhole, props.priceRemains, props.priceTotal || 1).remains)

		return {
			whole,
			remains
		}
	}

}
</script>