import { ref } from 'vue'
import axios from '@/plugins/axios'
import { defineStore } from 'pinia'

export const useCatalogStore = defineStore('catalog', () => {
	const loading = ref(false)
	const list = ref([])
	const depth = ref(0)

	const headerWidgetModel = ref(false)

	const get = async (_depth = 3) => {
		if (loading.value) return

		loading.value = true

		try {
			const response = await axios.get(window.config.url.CATALOG, {
				params: {
					depth: _depth
				}
			})
			list.value = response.data
			depth.value = _depth
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		}

		loading.value = false
	}

	const toggleHeaderWidgetModel = value => {
		headerWidgetModel.value = value
	}

	return {
		loading,
		list,
		depth,

		get,

		headerWidgetModel,
		toggleHeaderWidgetModel
	}
})
