import './styles/quasar.scss'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'
import lang from 'quasar/lang/ru.js'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'

import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
	config: {},
	plugins: {
		Dialog,
		Notify
	},
	lang: lang,
	iconSet: iconSet
}