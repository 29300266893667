/* eslint-disable */
import axios from 'axios';
import store from 'store2'
import {useAuthStore} from '@/stores/auth'


// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

//console.log(store.get('auth').accessToken);
const config = {
  baseURL: window.config.params.API,
  //baseURL: "/api?url=",
  // timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};


const _axios = axios.create(config);


_axios.interceptors.request.use(
    config => {
        // Do something before request is sent
        if (store.has('auth') && store.get('auth').accessToken != '') {          
          config.headers.Authorization  = `Bearer ${store.get('auth').accessToken}` 
        }
        return config
    },
    error =>{
        // Do something with request error
        console.log(error)
        Promise.reject(error)
    }

);

_axios.interceptors.response.use(
  response => response,
  async function (error) {
    const originalRequest = error.config;
    if (store.get('auth') && store.get('auth').accessToken != '' && error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await useAuthStore().login()
      return await _axios(originalRequest);
    }
    return Promise.reject(error);
  }  
);

export default _axios;
