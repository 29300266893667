<template>
    <div>
        <div v-if="!product.saldo">
            <QBtn :class="{'full-width': card}"
                  :size="size"
                  color="grey"
                  disable
                  label="Нет в наличии"
                  no-caps
                  rounded
                  style="height: 42px"
                  unelevated/>
        </div>
        <div v-else-if="!total">
            <QBtn v-if="card"
                  :size="size"
                  class="full-width"
                  color="primary"
                  label="В корзину"
                  no-caps
                  rounded
                  style="height: 42px"
                  unelevated
                  @click.stop="add"
            />
            <QBtn v-else
                  :size="size"
                  color="primary"
                  icon-right="fas fa-shopping-cart"
                  label="Добавить в корзину"
                  rounded
                  style="height: 42px"
                  unelevated
                  @click.stop="add"
            />
        </div>
        <div v-else>
            <div class="addToCardBlock border-round-lg">
                <QInput
                    v-model="totalModel"
                    :loading="loading.set"
                    borderless
                    color="primary"
                    dense
                    input-class="text-center"
                    mask="# шт"
                    reverse-fill-mask
                    @click.stop="stopPropagation"
                >
                    <template v-slot:before>
                        <QBtn
                            :dense="card"
                            :disable="loading.set"
                            color="primary"
                            flat
                            round
                            @click.stop="update(-1)"
                        >
                            <QIcon
                                class="text-sm"
                                color="q-gray-4"
                                name="fas fa-minus"
                            />
                        </QBtn>
                    </template>

                    <template v-slot:after>
                        <QBtn
                            :dense="card"
                            :disable="loading.set || total >= product.saldo"
                            color="primary"
                            flat
                            round
                            @click.stop="update(1)"
                        >
                            <QIcon
                                class="text-sm"
                                color="q-gray-4"
                                name="fas fa-plus"
                            />
                        </QBtn>
                    </template>
                </QInput>
            </div>

        </div>
    </div>
</template>

<script>
import {computed} from 'vue'
import {useCartStore} from '@/stores/cart'
import {storeToRefs} from 'pinia'

export default {
	name: 'AddToCart',

	props: {
		product: {
			type: Object,
			required: true
		},

		card: {
			type: Boolean,
			default: false
		},

		size: {
			type: String,
			default: 'md'
		}
	},

	setup(props) {
		const cartStore = useCartStore()

		const cartProduct = computed(() => {
			return cartStore.products?.find(el => el.service.id === props.product.id)
		})

		const total = computed(() => {
			return cartProduct.value ? cartProduct.value.total : 0
		})

		const totalModel = computed({
			get() {
				return total.value
			},

			set(value) {
				let _value = parseInt(value)

				if (_value > props.product.saldo) {
					totalModel.value = props.product.saldo
					return false
				}

				cartStore.set(props.product, _value)
			}
		})

		const add = () => {
			cartStore.set(props.product, 1)
		}

		const update = (value = 0) => {
			cartStore.set(props.product, total.value + value)
		}

		const {loading} = storeToRefs(cartStore)

		const stopPropagation = () => {
		}

		return {
			cartProduct,
			total,
			totalModel,

			add,
			update,

			loading,

			stopPropagation
		}
	}
}
</script>

<style lang="scss" scoped>
.addToCardBlock {
  border: 1px solid $grey-4
}

.addToCardBlock:deep(.q-field__append) {
  position: absolute;
  right: 0;
}
</style>