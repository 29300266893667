<template>
    <div class="catalog-main__group catalog-group">
        <div class="catalog-group__list">
            <div v-for="category of children"
                 :key="category.id"
                 class="catalog-group__list-item">
                <QBtn class="catalog-group__list-item-link"
                      :label="category.name"
                      size="sm"
                      no-caps
                      flat
                      @click="toCategory(category)"
                />
            </div>
        </div>

    </div>
</template>

<script>
import { useRouter } from 'vue-router'

import { useCatalogStore } from '@/stores/catalog'
import { computed } from 'vue'

export default {
	name: 'CatalogHeaderWidgetGroup',
	props: {
		children: {
			type: Array,
			required: true
		},
		path: {
			type: String,
			required: true
		}
	},
	setup(props) {
		const router = useRouter()

		const catalogStore = useCatalogStore()

		const catalogWidgetModel = computed({
			get() {
				return catalogStore.headerWidgetModel
			},

			set(value) {
				catalogStore.toggleHeaderWidgetModel(value)
			}
		})

		const toCategory = category => {
			router.push({ path: `/catalog${props.path}/${category.friendlyUrl}` })
			catalogWidgetModel.value = false
		}

		return {
			toCategory
		}
	}
}
</script>

<style scoped lang="scss">
.catalog-group__list {
  padding-left: 16px;
}

.catalog-group__list-item-link {
  color: $grey-7;
}
</style>
