import {computed, ref} from 'vue'
import axios from '@/plugins/axios'
import {defineStore} from 'pinia'
import store from 'store2'

import {useAuthStore} from '@/stores/auth'

export const useFavoriteStore = defineStore('favorite', () => {
	const KEY = 'favoriteProducts'

	const loading = ref({
		sync: false,
		get: false,
		add: false,
		remove: false,
		toggle: false
	})
	const list = ref([])
	const error = ref(null)

	const authStore = useAuthStore()

	const inLocalStorage = computed(() => {
		return !authStore.isAuth
	})

	const hasInLocalStorage = computed(() => {
		const localStorageList = store.get(KEY, [])
		return localStorageList.length > 0
	})

	const clearLocalStorage = () => {
		store.remove(KEY)
	}

	const sync = async () => {
		loading.value.sync = true
		const favoriteProductsId = store.get(KEY, [])

		try {
			await axios.post(`${window.config.url.FAVORITE}/sync`, {
				list: favoriteProductsId
			})

			clearLocalStorage()
		} catch (_error) {
			error.value = _error
		} finally {
			loading.value.sync = false
		}
	}

	const get = async () => {
		loading.value.get = true

		if (inLocalStorage.value) {
			list.value = store.get(KEY, [])
		} else {
			if (hasInLocalStorage.value) {
				await sync()
			}

			try {
				const {data} = await axios.get(window.config.url.FAVORITE, {
					params: {
						onlyServiceId: true
					}
				})

				list.value = data
			} catch (_error) {
				error.value = _error
			}
		}

		loading.value.get = false
	}


	const has = service => {
		return list.value.indexOf(service.id) !== -1
	}

	const add = async service => {
		if (inLocalStorage.value) {
			list.value.push(service.id)

			store.set(KEY, list.value)
		} else {
			loading.value.add = true

			try {
				await axios.post(window.config.url.FAVORITE, {
					id: service.id
				})

				list.value.push(service.id)
			} catch (_error) {
				error.value = _error
			} finally {
				loading.value.add = false
			}
		}
	}

	const remove = async service => {
		if (inLocalStorage.value) {
			list.value = list.value.filter(serviceId => serviceId !== service.id)

			store.set(KEY, list.value)
		} else {
			loading.value.remove = true

			try {
				await axios.delete(`${window.config.url.FAVORITE}/${service.id}`)

				list.value = list.value.filter(serviceId => serviceId !== service.id)
			} catch (_error) {
				error.value = _error
			} finally {
				loading.value.remove = false
			}
		}
	}

	const toggle = async service => {
		loading.value.toggle = true

		if (has(service)) {
			await remove(service)
		} else {
			await add(service)
		}

		loading.value.toggle = false
	}

	const clear = async () => {
		if (inLocalStorage.value) {
			list.value = []

			store.set(KEY, list.value)
		} else {
			loading.value.clear = true

			try {
				await axios.delete(window.config.url.FAVORITE)

				list.value = []
			} catch (_error) {
				error.value = _error
			} finally {
				loading.value.clear = false
			}
		}
	}

	return {
		loading,
		list,
		error,

		inLocalStorage,
		hasInLocalStorage,

		get,
		has,
		add,
		remove,
		toggle,
		clear
	}
})
