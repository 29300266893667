<template>
    <div ref="container"
         class="search-header-widget">
        <QCard class="q-pa-md">
            <div class="search-header-widget__list scrollbar">
                <template v-if="loading">
                    <QSkeleton v-for="index in 5"
                               :key="index"
                               height="80px"/>
                </template>
                <template v-else>
                    <div v-if="!products.length">
                        <QBanner class="text-center text-grey-6">
                            По вашему запросу нет подходящих товаров
                        </QBanner>
                    </div>
                    <template v-else>
                        <SearchLineProduct v-for="product in products"
                                           :key="product.id"
                                           :product="product"/>
                    </template>

                </template>
            </div>
        </QCard>
    </div>
</template>

<script>
import SearchLineProduct from '@/components/search/SearchLineProduct.vue'

import {onMounted, onUnmounted, ref} from 'vue'

import {useSearchStore} from '@/stores/search'
import {storeToRefs} from 'pinia'

export default {
	name: 'SearchHeaderWidget',

	components: {
		SearchLineProduct
	},

	props: {
		input: {
			type: HTMLElement
		}
	},

	setup(props) {
		const container = ref(null)
		const searchStore = useSearchStore()

		const onClickOutside = event => {
			if (event.target !== container.value && !container.value.contains(event.target) && props.input !== event.target && !props.input?.contains(event.target)) {
				searchStore.toggleShow(false)
			}
		}

		onMounted(() => {
			document.addEventListener('click', onClickOutside)
		})

		onUnmounted(() => {
			document.removeEventListener('click', onClickOutside)
		})

		const {products, total, loading} = storeToRefs(searchStore)

		return {
			container,

			products,
			total,
			loading
		}
	}
}
</script>

<style scoped>
.search-header-widget__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 80vh;
  overflow-y: auto;
}
</style>
