<template>
    <div class="catalog-header-widget__main catalog-main">
        <template v-if="children.length">
            <QBtn v-if="parent"
                  :label="parent.name"
                  class="catalog-main__title text-h6 q-mb-sm text-weight-bolder"
                  flat
                  @click="toParentCategory"
            />
            <div class="catalog-main__grid">
                <div v-for="category of children"
                     :key="category.id"
                     class="catalog-main__group">
                    <QBtn :label="category.name"
                          class="text-weight-bold"
                          flat
                          no-caps
                          @click="toCategory(category)"
                    />

                    <CatalogHeaderWidgetGroup
                        :children="category.children"
                        :path="`/${parent.friendlyUrl}/${category.friendlyUrl}`"/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import CatalogHeaderWidgetGroup from '@/components/catalog/CatalogHeaderWidgetGroup'

import {useRouter} from 'vue-router'

import {useCatalogStore} from '@/stores/catalog'
import {computed} from 'vue'

export default {
	name: 'CatalogHeaderWidgetMain',

	components: {
		CatalogHeaderWidgetGroup
	},

	props: {
		children: {
			type: Array,
			required: true
		},
		parent: {
			type: Object
		}
	},

	setup(props) {
		const router = useRouter()
		const catalogStore = useCatalogStore()

		const catalogWidgetModel = computed({
			get() {
				return catalogStore.headerWidgetModel
			},

			set(value) {
				catalogStore.toggleHeaderWidgetModel(value)
			}
		})

		const toParentCategory = () => {
			router.push({path: `/catalog/${props.parent.friendlyUrl}`})
			catalogWidgetModel.value = false
		}

		const toCategory = category => {
			router.push({path: `/catalog/${props.parent.friendlyUrl}/${category.friendlyUrl}`})
			catalogWidgetModel.value = false
		}

		return {
			toParentCategory,
			toCategory
		}
	}
}
</script>

<style lang="scss" scoped>
.catalog-header-widget__main {
  background-color: white;
  flex: 1 1 auto;
  border-radius: 8px;
  padding: 16px;
  overflow: auto;
  width: 100%;
}

.catalog-main__grid {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(200px, 1fr));
  gap: 16px;
}
</style>
