export function hexToRgb(hex, asObject = false) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	return result ? (asObject ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}`) : null
}

export function colorFromString(str = '') {
	let hash = 0
	for (let i = 0; i < str.length; i++) {
		// eslint-disable-next-line no-bitwise
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}
	let color = '#'
	for (let i = 0; i < 3; i++) {
		// eslint-disable-next-line no-bitwise
		let value = (hash >> (i * 8)) & 0xFF
		color += ('00' + value.toString(16)).substr(-2)
	}
	return color
}

export function rgbToYIQ({r, g, b}) {
	return ((r * 299) + (g * 587) + (b * 114)) / 1000
}

export function contrast(colorHex, threshold = 128) {
	if (colorHex === undefined) {
		return '#000'
	}

	const rgb = hexToRgb(colorHex, true)

	if (rgb === undefined) {
		return '#000'
	}

	return rgbToYIQ(rgb) >= threshold ? '#000' : '#fff'
}
