<template>
    <div :style="{'width': size, 'height': size, 'border-radius': borderRadius, 'box-shadow': boxShadow}"
         :class="$props.class"
         class="avatar">
        <img v-if="src"
             :src="src"
             class="avatar__content">
        <div v-else
             :style="{'color': color, 'background': background, 'font-size': fontSize}"
             class="avatar__content avatar__content--text">
            <QIcon v-if="icon"
                   :name="icon"/>
            <template v-else>{{ shortName }}</template>
        </div>
    </div>
</template>

<script>
import {computed} from 'vue'
import {colorFromString, contrast} from '@/tools/color'

export default {
	name: 'Avatar',

	props: {
		src: String,
		name: String,
		size: {
			type: String,
			default: '34px'
		},
		icon: String,
		fontSize: {
			type: String,
			default: '1rem'
		},
		borderRadius: {
			type: String,
			default: '50%'
		},
		gradient: {
			type: Boolean,
			default: false
		},
		backgroundColor: {
			type: String
		},
		alpha: {
			type: String,
			default: ''
		},
		class: {
			type: [String, Array, Object],
			default: ''
		},
		boxShadow: {
			type: String,
			default: ''
		}
	},

	setup(props) {
		const shortName = computed(() => {
			const splitName = props.name.split(' ')
			return splitName.map(name => name[0].toUpperCase()).join('').substr(0, 2)
		})

		const backgroundColor = computed(() => {
			return props.backgroundColor || colorFromString(props.name)
		})

		const background = computed(() => {
			return props.gradient ? `linear-gradient(0deg, ${backgroundColor.value} 0%, ${backgroundColor.value}85 100%)` : backgroundColor.value + props.alpha
		})

		const color = computed(() => {
			return contrast(backgroundColor.value)
		})

		return {
			shortName,
			background,
			color
		}
	}
}
</script>

<style scoped>
.avatar {
  background-color: white;
  overflow: hidden;
}

.avatar__content {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.avatar__content.avatar__content--text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
</style>
