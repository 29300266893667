<template>
    <div class="search-line-product">
        <div class="search-line-product__content">
            <QImg v-if="image"
                  :src="image"
                  class="search-line-product__image"
                  fit="contain"
                  height="70px"
                  width="70px"/>

            <div class="search-line-product__info flex column q-gutter-md full-width">
                <div class="search-line-product__name ellipsis-2-lines"
                     @click="select">
                    {{ product.name }}
                </div>

                <div class="search-line-product__control flex justify-between">
                    <div class="search-line-product__price">
                        <PriceTotal 
                            :price-remains="product.price.remains"
                            :price-whole="product.price.whole"
                            :approximately="product.approximately"
                            :hidePrice="product.hidePrice"
                        />
                    </div>

                    <AddToCart :product="product"
                               size="sm"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddToCart from '@/components/product/AddToCart.vue'
import PriceTotal from '@/components/product/PriceTotal.vue'

import {computed} from 'vue'
import {useRouter} from 'vue-router'

import {useSearchStore} from '@/stores/search'

export default {
	name: 'SearchLineProduct',

	components: {
		AddToCart,
		PriceTotal
	},

	props: {
		product: {
			type: Object,
			required: true
		}
	},

	setup(props) {
		const searchStore = useSearchStore()

		const image = computed(() => {
			return props.product.images.length ? props.product.images[0] : null
		})

		const router = useRouter()
		const select = () => {
			router.push({path: `/product/${props.product.friendlyUrl}`})
			searchStore.toggleShow(false)
		}

		return {
			image,
			select
		}
	}
}
</script>

<style lang="scss" scoped>
.search-line-product__content {
  display: flex;
  gap: 16px;
}

.search-line-product__image {
  flex: none;
}

.search-line-product__name {
  cursor: pointer;
}

.search-line-product__name:hover {
  color: var(--q-primary);
}
</style>