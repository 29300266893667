import {computed, ref} from 'vue'
import axios from '@/plugins/axios'
import {defineStore} from 'pinia'

export const useInfoStore = defineStore('info', () => {
	const loading = ref(true)

	const logo = ref('')
	const name = ref('')
	const shortName = ref('')
	const primaryColor = ref('')
	const mainSiteUrl = ref('')
	const attributesTable = ref('')
	const fullDescription = ref('')
	const showSpecifications = ref('')
	const showDescription = ref('')
	const showMiniDescription = ref('')
	const showReviews = ref('')
	const phone = ref('')
	const address = ref('')
	const filials = ref([])
	const structure = ref([])
	const active = ref(false)
	const delivery = ref([])

	const get = async () => {
		loading.value = true

		try {
			const {data} = await axios(window.config.url.INIT)
			logo.value = data.logo
			name.value = data.name
			shortName.value = data.shortName
			primaryColor.value = data.primaryColor
			mainSiteUrl.value = data.mainSiteUrl ? data.mainSiteUrl : data.shopUrl
			showSpecifications.value = data.showSpecifications
			attributesTable.value = data.attributesTable
			fullDescription.value = data.fullDescription
			showDescription.value = data.showDescription
			showMiniDescription.value = data.showMiniDescription
			showReviews.value = data.showReviews
			phone.value = data.phone
			address.value = data.address
			filials.value = data.filials
			structure.value = data.structure
			active.value = data.active
			delivery.value = data.delivery
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			loading.value = false
		}
	}

	const inIframe = computed(() => {
		try {
			return window.self !== window.top
		} catch (e) {
			return true
		}
	})


	return {
		loading,
		logo,
		name,
		shortName,
		primaryColor,
		mainSiteUrl,
		showSpecifications,
		showDescription,
		showMiniDescription,
		attributesTable,
		fullDescription,
		showReviews,
		phone,
		address,
		filials,
		structure,
		inIframe,
		active,
		delivery,

		get
	}
})
