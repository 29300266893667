import {computed, ref} from 'vue'
import axios from '@/plugins/axios'
import store from 'store2'
import {defineStore} from 'pinia'

import {useAuthStore} from '@/stores/auth'
import {useInfoStore} from '@/stores/info'

import {sumTotal} from '@/use/price'

export const useCartStore = defineStore('cart', () => {
	const KEY = 'cartProducts'
	const authStore = useAuthStore()
	const infoStore = useInfoStore()

	const products = ref([])
	const loading = ref({
		get: false,
		update: false,
		remove: false,
		set: false,
		clear: false,
		order: false
	})
	const error = ref(null)
	const delivery = ref({
		loading: false,
		name: null,
		isFloatingPrice: false,
		price: 0,
		active: false,
		params: {
			id: null,
			city: '',
			cityName: '',
			address: ''
		}
	})

	const clientDeliveryParams = computed(() => {
		return infoStore.delivery.find(el => el.value === 'client')?.params
	})

	const inLocalStorage = computed(() => {
		return !authStore.isAuth
	})

	const hasInLocalStorage = computed(() => {
		return store.has(KEY) && store.get(KEY)?.length > 0
	})

	const clearLocalStorage = () => {
		store.remove(KEY)
	}

	const sync = async () => {
		const lsProducts = store.get(KEY, [])

		try {
			await axios.post(window.config.url.CART_LIST, {
				list: lsProducts
			})

			clearLocalStorage()
		} catch (_error) {
			error.value = _error
		}
	}

	const get = async () => {
		loading.value.get = true

		if (inLocalStorage.value) {
			const lsProducts = store.get(KEY, [])
			if (lsProducts.length) {
				try {
					const {data} = await axios.get(window.config.url.PRODUCT, {
						params: {
							idList: lsProducts.map(el => el.id)
						}
					})


					products.value = data.map(el => {
						return {
							total: lsProducts.find(lsEl => lsEl.id === el.id)?.total,
							service: el
						}
					})
				} catch (_error) {
					error.value = _error
				}
			}
		} else {
			if (hasInLocalStorage.value) {
				await sync()
			}

			try {
				const {data} = await axios.get(window.config.url.CART)

				products.value = data
			} catch (_error) {
				error.value = _error
			}
		}

		loading.value.get = false
	}

	const createOrder = async ({comment, filial}) => {
		loading.value.order = true

		let response = null

		try {
			const {data} = await axios.post(window.config.url.ORDER, {
				comment,
				filial: delivery.value.active ? null : filial,
				delivery: delivery.value.active ? JSON.stringify({
					name: delivery.value.name,
					params: delivery.value.params
				}) : null
			})
			response = data
		} catch (error) {
			throw error
		} finally {
			loading.value.order = false
		}

		return response
	}

	const has = product => {
		return products.value.some(el => el.service?.id === product.id)
	}

	const find = product => {
		return products.value.find(el => el.service?.id === product.id)
	}

	const update = async (product, total = 1) => {
		loading.value.update = true

		if (inLocalStorage.value) {
			const cartProduct = products.value.find(el => el.service?.id === product.id)

			if (cartProduct) {
				cartProduct.total = total
			} else {
				products.value.push({
					total: total,
					service: product
				})
			}

			store.set(KEY, products.value.map(el => {
				return {
					total: el.total,
					id: el.service.id
				}
			}))
		} else {
			// axios update
			try {
				await axios.post(window.config.url.CART_EDIT + '/' + product.friendlyUrl, {
					total: total
				})

				const cartProduct = products.value.find(el => el.service?.id === product.id)

				if (cartProduct) {
					cartProduct.total = total
				} else {
					products.value.push({
						total: total,
						service: product
					})
				}

			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error)
			}
		}

		loading.value.update = false
	}

	const remove = async product => {
		loading.value.remove = true

		if (inLocalStorage.value) {
			products.value = products.value.filter(el => el.service.id !== product.id)

			store.set(KEY, products.value.map(el => {
				return {
					total: el.total,
					id: el.service.id
				}
			}))
		} else {
			// axios remove
			await axios.delete(window.config.url.CART_EDIT + '/' + product.friendlyUrl)

			products.value = products.value.filter(el => el.service.id !== product.id)
		}

		loading.value.remove = false
	}

	const clear = async () => {
		loading.value.clear = true

		if (inLocalStorage.value) {
			products.value = []
			store.remove(KEY)
		} else {
			// axios clear
		}

		loading.value.clear = false
	}

	const set = async (product, total = 1) => {

		loading.value.set = true
		if (total > 0) {
			await update(product, total)
		} else {
			await remove(product)
		}

		loading.value.set = false
	}

	const priceProducts = computed(() => {
		return products.value.reduce((acc, item) => {
			const total = sumTotal(item.service.price.whole, item.service.price.remains, item.total)
			return acc + (item.service.hidePrice ? 0.0 : ((total.whole * 100) + total.remains))
		}, 0) || 0
	})

	const priceProductsWhole = computed(() => {
		return priceProducts.value > 0 ? Math.trunc(priceProducts.value / 100) : 0
	})

	const priceProductsRemains = computed(() => {
		return priceProducts.value > 0 ? Math.trunc(priceProducts.value % 100) : 0
	})

	const priceTotal = computed(() => {
		return priceProducts.value + (delivery.value.active ? delivery.value.price * 100 : 0)
	})

	const priceTotalWhole = computed(() => {
		return priceTotal.value > 0 ? Math.trunc(priceTotal.value / 100) : 0
	})

	const priceTotalRemains = computed(() => {
		return priceTotal.value > 0 ? Math.trunc(priceTotal.value % 100) : 0
	})

	const approximately = computed(() => {
		return products.value.reduce((accumulator, item) => {

			return !accumulator ? item.service.approximately : true
		}, 0) || (delivery.value.active && delivery.value.isFloatingPrice)
	})

	const instock = computed(() => {
		return !products.value.some(el => {
			return el.total > el.service.saldo
		})
	})

	const toggleDelivery = (value = !delivery.value.active) => {
		delivery.value.active = value
	}

	const toggleDeliveryFloatingPrice = (value = !delivery.value.isFloatingPrice) => {
		delivery.value.isFloatingPrice = value
	}

	const toggleDeliveryLoading = (value = !delivery.value.loading) => {
		delivery.value.loading = value
	}

	const setDeliveryPrice = value => {
		delivery.value.price = value
	}

	const setDeliveryName = name => {
		delivery.value.name = name
	}

	const setDeliveryParams = (params = {}) => {
		delivery.value.params = {...delivery.value.params, ...params}
	}

	const resetDelivery = (deactivate = true) => {
		delivery.value.active = !deactivate
		delivery.value.loading = false
		delivery.value.isFloatingPrice = false
		delivery.value.price = 0
		delivery.value.params = {
			id: null,
			city: '',
			cityName: '',
			address: ''
		}
	}

	const validDelivery = computed(() => {
		if (delivery.value.params.id === 'courier') {
			return !!delivery.value.params.address && !!delivery.value.name
		} else {
			return !!delivery.value.params.id && !!delivery.value.name
		}
	})

	const goods = computed(() => {
		const _goods = []

		products.value.forEach(product => {
			const length = Number(product.service.specifications.find(sp => sp.name === 'length')?.value) || 0
			const height = Number(product.service.specifications.find(sp => sp.name === 'height')?.value) || 0
			const width = Number(product.service.specifications.find(sp => sp.name === 'width')?.value) || 0
			const weight = Number(product.service.specifications.find(sp => sp.name === 'weight')?.value) || 0

			_goods.push(...Array(product.total).fill({
				length,
				height,
				weight,
				width
			}, 0))
		})

		return _goods
	})

	return {
		products,
		loading,
		error,

		inLocalStorage,
		hasInLocalStorage,

		priceProducts,
		priceProductsWhole,
		priceProductsRemains,
		priceTotal,
		priceTotalWhole,
		priceTotalRemains,
		approximately,
		instock,

		delivery,
		goods,
		validDelivery,
		toggleDelivery,
		toggleDeliveryFloatingPrice,
		toggleDeliveryLoading,
		setDeliveryPrice,
		setDeliveryName,
		setDeliveryParams,
		resetDelivery,

		clearLocalStorage,
		createOrder,
		sync,
		get,
		find,
		has,
		set,
		clear,
		remove,

		clientDeliveryParams
	}
})
