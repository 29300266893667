import {computed, ref, watch} from 'vue'
import axios from '@/plugins/axios'
import {defineStore} from 'pinia'
import {debounce} from '@/tools/debounce'

export const useSearchStore = defineStore('search', () => {
	const loading = ref(false)

	const products = ref([])
	const total = ref(0)
	const error = ref(null)
	const offset = computed(() => {
		return products.value.length
	})

	const q = ref('')
	const model = ref('')
	const show = ref(false)

	const canShow = computed(() => {
		return q.value.length >= 3
	})

	const search = async () => {
		if (loading.value) return

		loading.value = true

		try {
			const {data} = await axios.get(`${window.config.url.SEARCH}/${q.value}`, {
				params: {
					offset: 0,
					limit: window.config.searchBar.LIMIT
				}
			})

			products.value = data.list
			total.value = data.total
		} catch (_error) {
			error.value = _error
		} finally {
			loading.value = false
		}
	}

	const trigger = () => {
		if (canShow.value) {
			show.value = true
			search()
		} else {
			show.value = false
		}
	}

	const set = (_q = '') => {
		model.value = _q
	}

	const updateQuery = () => {
		q.value = model.value

		trigger()
	}

	watch(model, () => {
		debounce(updateQuery, 500)
	})

	const toggleShow = (value = true) => {
		show.value = value
	}

	return {
		loading,
		products,
		total,
		offset,

		search,

		model,
		q,
		set,

		show,
		canShow,
		toggleShow,

		trigger
	}
})
