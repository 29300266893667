import {ref} from 'vue'
import {defineStore} from 'pinia'

export const useSidebarStore = defineStore('sidebar', () => {
	const show = ref(false)

	const toggle = (value = !show.value) => {
		show.value = value
	}

	return {
		show,

		toggle
	}
})
