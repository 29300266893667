<template>
    <QFooter :elevated="false"
             class="bg-grey-2"
    >
        <QToolbar>
            <QToolbarTitle>
                <div class="container row text-xs md:text-sm">
                    <div v-if="shortName && address"
                         id="footerCompany"
                         class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 flex content-center">
                        <span class="full-width text-dark text-weight-bold"> © {{ year }} {{ shortName }} / {{ address }}</span>
                    </div>
                    <div v-if="phone"
                         class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 flex content-center justify-center">
                        <QBtn color="primary"
                              flat
                              rounded>
                            <QIcon class="q-mr-sm"
                                   color="primary"
                                   name="fas fa-phone-alt"
                                   size="14px"/>
                            <a :href="`tel:${phone}`"
                               class="text-xs md:text-sm text-dark no-underline text-center">{{ phone }}</a>
                        </QBtn>
                    </div>
                </div>
            </QToolbarTitle>
        </QToolbar>
    </QFooter>
</template>

<script>
import {storeToRefs} from 'pinia'
import {useInfoStore} from '@/stores/info'
import {ref} from 'vue'
import moment from 'moment/moment'

export default {
	name: 'AppFooter',

	setup() {
		const infoStore = useInfoStore()
		const {phone, address, shortName} = storeToRefs(infoStore)

		const year = ref(moment().format('YYYY'))

		return {
			phone,
			address,
			shortName,
			year
		}
	}
}
</script>

<style scoped>
#footerBar {
  font-size: 14px;
}
</style>
