<template>
    <div v-if="!isHomeView"
         class="nav-breadcrumb">
        <template v-if="breadcrumbStore.loading">
            <div class="flex q-gutter-sm"
                 style="height: 29px">
                <QSkeleton v-for="index in 5"
                           :key="index"
                           type="QBadge"/>
            </div>
        </template>
        <template v-else>
            <QBreadcrumbs gutter="sm">
                <template #separator>
                    <QIcon
                        name="fas fa-chevron-right"
                        size="10px"
                        style="margin-bottom: 3px"
                    />
                </template>

                <QBreadcrumbsEl label="Главная"
                                to="/"/>

                <QBreadcrumbsEl v-for="path in breadcrumbStore.path"
                                :key="path.to"
                                :label="path.label"
                                :to="path.to"/>
            </QBreadcrumbs>
        </template>
    </div>
</template>

<script>
import {computed} from 'vue'
import {useRoute} from 'vue-router'

import {useBreadcrumbStore} from '@/stores/breadcrumb'

export default {
	name: 'NavBreadcrumb',

	setup() {
		const route = useRoute()
		const breadcrumbStore = useBreadcrumbStore()

		const isHomeView = computed(() => {
			return route.path === '/'
		})

		return {
			isHomeView,
			breadcrumbStore
		}
	}
}
</script>

<style scoped>
.nav-breadcrumb {
  padding: 20px 0;
}
</style>
