import axios from './plugins/axios'
import VueAxios from 'vue-axios'
import {createApp} from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'
import {createPinia} from 'pinia'
import VueTilt from 'vue-tilt.js'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import '@/styles/style.css'

const app = createApp(App)

app.use(router)
	.use(VueAxios, axios)
	.use(Quasar, quasarUserOptions)
	.use(createPinia())
	.use(VueTilt)
	.use(VueViewer)
	.mount('#app')