<template>
    <div>
        <div v-if="showAuth">
            <QDialog
                v-model="showAuth"
                maximized
                persistent
                transition-hide="slide-down"
                transition-show="slide-up"
            >
                <QCard class="bg-white">
                    <QCardSection class="flex"
                                  style="height: 64px;">
                        <h5 class="text-h5 q-ma-none">Вход в аккаунт</h5>

                        <QSpace/>

                        <QBtn v-close-popup
                              dense
                              flat
                              icon="fas fa-times"
                              rounded
                              @click="infoStore.hideAuth()"/>
                    </QCardSection>
                    <QCardSection
                        id="iframe-auth"
                        class="q-pt-none p-0"
                    >
                        <div class="relative-position">
                            <iframe
                                :src="appUrl"
                                frameborder="0"
                                scrolling="yes"
                                style="height:700px;overflow: hidden;"
                                width="100%"
                                @load="loadingIframe = false"
                            />
                            <QSpinner v-if="loadingIframe"
                                      color="primary"
                                      size="3em"
                                      style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
                            />
                        </div>
                    </QCardSection>
                </QCard>
            </QDialog>
        </div>

        <div v-if="infoStore.loading"
             class="app-loader">
            <QSpinnerPuff
                color="black"
                size="48px"
            />
        </div>
        <div v-else-if="!infoStore.active"
             class="relative-position">
            <QImg :src="require('@/assets/images/cloud.png')"
                  fit="cover"
                  height="100vh"
                  width="100vw"
            />
            <div class="absolute-center">
                <QImg :src="require('@/assets/images/shop.png')"
                      fit="contain"
                      height="256px"
                />
                <QBanner class="bg-transparent q-mt-lg border-round-xs text-center">
                    <div class="text-h5 text-weight-bold text-blue-10 text-uppercase">Временно недоступен :(</div>
                    <div class="q-mt-sm">Мы приносим свои извинения, магазин скоро будет запущен</div>
                </QBanner>
            </div>
        </div>
        <div
            v-else>
            <QLayout view="hHh lpR fff">
                <AppHeader/>

                <QDrawer
                    v-model="sidebarModel"
                    :breakpoint="600"
                    :width="350"
                    elevated
                    overlay
                >

                    <QScrollArea
                        :horizontal-thumb-style="{ opacity: 0 }"
                        :style="infoStore.inIframe ? 'height: 100%' : 'height: calc(100% - 150px); margin-top: 150px;'"
                    >
                        <QList padding>
                            <QItem v-ripple
                                   clickable
                                   @click="toHome"
                            >
                                <QItemSection avatar>
                                    <QIcon color="primary"
                                           name="fas fa-home"/>
                                </QItemSection>

                                <QItemSection>
                                    Главная
                                </QItemSection>
                            </QItem>

                            <QItem v-ripple
                                   clickable
                                   @click="toCatalog"
                            >
                                <QItemSection avatar>
                                    <QIcon color="primary"
                                           name="fas fa-list"/>
                                </QItemSection>

                                <QItemSection>
                                    Каталог
                                </QItemSection>
                            </QItem>

                            <QItem v-ripple
                                   clickable
                                   @click="toSearch"
                            >
                                <QItemSection avatar>
                                    <QIcon color="primary"
                                           name="fas fa-search"/>
                                </QItemSection>

                                <QItemSection>
                                    Поиск
                                </QItemSection>
                            </QItem>

                            <QItem v-ripple
                                   clickable
                                   @click="toFavorite"
                            >
                                <QItemSection avatar>
                                    <QIcon color="primary"
                                           name="fas fa-heart"/>
                                </QItemSection>

                                <QItemSection>
                                    Избранное
                                </QItemSection>
                            </QItem>

                            <QItem v-ripple
                                   clickable
                                   @click="toCart"
                            >
                                <QItemSection avatar>
                                    <QIcon color="primary"
                                           name="fas fa-shopping-cart"/>
                                </QItemSection>

                                <QItemSection>
                                    Корзина
                                </QItemSection>
                            </QItem>

                            <QItem v-if="authStore.isAuth"
                                   v-ripple
                                   clickable
                                   @click="logout"
                            >
                                <QItemSection avatar>
                                    <QIcon color="primary"
                                           name="fas fa-sign-out-alt"/>
                                </QItemSection>

                                <QItemSection>
                                    Выйти
                                </QItemSection>
                            </QItem>
                            <QItem v-else-if="!infoStore.inIframe"
                                   v-ripple
                                   clickable
                                   @click="login"
                            >
                                <QItemSection avatar>
                                    <QIcon color="primary"
                                           name="fas fa-sign-out-alt"/>
                                </QItemSection>

                                <QItemSection>
                                    Войти
                                </QItemSection>
                            </QItem>
                        </QList>
                    </QScrollArea>

                    <QImg
                        v-if="!infoStore.inIframe"
                        class="absolute-top bg-grey-2"
                        style="height: 150px">
                        <div class="absolute-bottom bg-transparent">
                            <Avatar
                                :background-color="authStore.isAuth ? null : '#b3b3b3'"
                                :name="authStore.isAuth ? `${authStore.user.surname} ${authStore.user.name}` : 'Неавторизованный пользователь'"
                                box-shadow="0 0 0 2px white, 0 0 0 4px var(--q-primary)"
                                class="q-mb-sm"
                                size="56px"
                            />
                            <div class="text-weight-bold text-subtitle1 text-dark"
                                 @click="toProfile">{{
                                     authStore.isAuth ? `${authStore.user.surname} ${authStore.user.name}` : 'Неавторизованный пользователь'
                                 }}
                            </div>
                        </div>
                    </QImg>
                </QDrawer>

                <div id="rootContainer"
                     class="container">
                    <QPageContainer>
                        <div class="q-py-md">
                            <NavBreadcrumb/>
                            <RouterView/>
                        </div>
                    </QPageContainer>
                </div>
                <AppFooter v-if="!infoStore.inIframe"/>
            </QLayout>
        </div>
    </div>
</template>

<script>
import AppFooter from '@/components/AppFooter'
import AppHeader from '@/components/AppHeader'

import Avatar from '@/components/Avatar.vue'

import NavBreadcrumb from '@/components/NavBreadcrumb'

import {computed, onErrorCaptured, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'

import {useInfoStore} from '@/stores/info'
import {useAuthStore} from '@/stores/auth'
import {useCartStore} from '@/stores/cart'
import {useErrorStore} from '@/stores/error'
import {useFavoriteStore} from '@/stores/favorite'
import {useSidebarStore} from '@/stores/sidebar'
import {setCssVar, useQuasar} from 'quasar'

export default {
	components: {
		AppFooter,
		AppHeader,
		NavBreadcrumb,
		Avatar
	},

	setup() {
		const infoStore = useInfoStore()
		const authStore = useAuthStore()
		const cartStore = useCartStore()
		const errorStore = useErrorStore()
		const favoriteStore = useFavoriteStore()

		const route = useRoute()

		const showAuth = computed({
			get() {
				return authStore.showAuthModal
			},
			set(value) {
				authStore.toggleAuth(value)
			}
		})

		const appUrl = ref(window.config.url.APP + '?authService=shop&url=' + route.path)

		authStore.info()
		infoStore.get()

		watch(() => route.path, value => {
			appUrl.value = window.config.url.APP + '?authService=shop&url=' + value
		})

		watch(() => infoStore.primaryColor, value => {
			setCssVar('primary', value)
		})

		watch(() => infoStore.loading, value => {
			if (!value) {
				favoriteStore.get()
				cartStore.get()
			}
		})

		watch(() => authStore.isAuth, value => {
			if (value) {
				favoriteStore.get()
				cartStore.get()
			}
		})

		onErrorCaptured(error => {
			// errorStore.push(error)
			// eslint-disable-next-line no-console
			console.error(error, errorStore.data)

			return false
		})

		const sidebarStore = useSidebarStore()
		const sidebarModel = computed({
			get() {
				return sidebarStore.show
			},

			set(value) {
				sidebarStore.toggle(value)
			}
		})

		const router = useRouter()
		const toCatalog = () => {
			router.push({path: '/catalog'})
		}

		const toHome = () => {
			router.push({name: 'home'})
		}

		const toFavorite = () => {
			router.push({name: 'favorite'})
		}

		const toCart = () => {
			router.push({name: 'cart'})
		}

		const toSearch = () => {
			router.push({name: 'search'})
		}

		const toProfile = () => {
			window.location = window.config.url.APP
		}

		const login = () => {
			authStore.showAuth()
		}

		const $q = useQuasar()
		const logout = () => {
			$q.dialog({
				title: 'Подтвердите действие',
				message: 'Вы действидельно хотите выйти со своего аккаунта?',
				cancel: true,
				persistent: true
			}).onOk(() => {
				authStore.logout()
			})
		}

		const loadingIframe = ref(false)
		watch(showAuth, value => {
			if (value) {
				loadingIframe.value = value
			}
		})

		return {
			infoStore,
			cartStore,
			authStore,
			showAuth,

			appUrl,

			sidebarModel,

			toCatalog,
			toProfile,
			toHome,
			toFavorite,
			toCart,
			toSearch,

			login,
			logout,

			loadingIframe
		}
	}
}
</script>

<style lang="scss" scoped>
.app-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-width: $breakpoint-md-max) {
  #rootContainer {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>

<style>
body {
  font-family: 'Nunito', sans-serif !important;
}

iframe .v-overlay__scrim {
  background-color: white;
}
</style>


