import {ref} from 'vue'
import {defineStore} from 'pinia'

export const useBreadcrumbStore = defineStore('breadcrumb', () => {
	const loading = ref(false)
	const toggleLoading = value => {
		loading.value = value
	}

	const path = ref([])

	const push = (...items) => {
		path.value.push(...items)
	}

	const pop = () => {
		return path.value.pop()
	}

	const shift = () => {
		return path.value.shift()
	}

	const unshift = (item = {}) => {
		path.value.unshift(item)
	}

	const set = (items = []) => {
		path.value = items

		loading.value = false
	}

	const clear = () => {
		path.value = []

		loading.value = false
	}

	return {
		path,

		push,
		pop,
		shift,
		unshift,
		set,
		clear,

		loading,
		toggleLoading
	}
})
