import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from '@/stores/auth'
import store from 'store2'

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => {
			return import('@/views/HomeView')
		},
		meta: {
			title: 'Главная'
		}
	},
	{
		path: '/product/:friendlyUrl',
		name: 'product',
		component: () => {
			return import('@/views/ProductView')
		},
		meta: {
			title: 'Продукт'
		}
	},
	{
		path: '/search',
		name: 'search',
		component: () => {
			return import('@/views/SearchView')
		},
		meta: {
			title: 'Поиск'
		}
	},
	{
		path: '/cart',
		name: 'cart',
		component: () => {
			return import('@/views/CartView')
		},
		meta: {
			title: 'Корзина'
		}
	},
	{
		path: '/favorite',
		name: 'favorite',
		component: () => {
			return import('@/views/FavoriteView')
		},
		meta: {
			title: 'Избранное'
		}
	},
	{
		path: '/product/:friendlyUrl',
		name: 'notFoundProduct',
		component: () => {
			return import('@/views/notFound/ProductNotFound')
		}
	},
	{
		path: '/catalog',
		name: 'catalog',
		component: () => {
			return import('@/views/layouts/CatalogLayout')
		},
		children: [
			{
				path: '',
				name: 'catalogHome',
				component: () => {
					return import('@/views/CatalogView')
				},
				meta: {
					title: 'Каталог'
				}
			},
			{
				path: ':friendlyUrlList(.*)*',
				name: 'catalogProducts',
				component: () => {
					return import('@/views/CatalogProductView')
				}
			}
		]
	},
	{
		path: '/error',
		name: 'error',
		component: () => {
			return import('@/views/ErrorView')
		},
		meta: {
			title: 'Ошибка'
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'notFound',
		component: () => {
			return import('@/views/notFound/PageNotFound')
		},
		meta: {
			title: 'Не найдено'
		}
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}

	// если авторизация через приложение 
	if (to.query.setRefreshToken) {

		const authStore = useAuthStore()

		store.set('auth', {
			accessToken: '',
			refreshToken: to.query.setRefreshToken
		})

		authStore.login()	
    
		// eslint-disable-next-line no-console
		next({path: to.path})
	} else {
		next()
	}
})

export default router
