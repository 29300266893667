// eslint-disable-next-line no-unused-vars
export const getPrice = function(price){
	/*
     * В переменной price приводим получаемую переменную в нужный вид:
     * 1. принудительно приводим тип в число с плавающей точкой,
     *    учли результат 'NAN' то по умолчанию 0
     * 2. фиксируем, что после точки только в сотых долях
     */
	let newPrice = price
	//let price_sep       = Number.prototype.toFixed.call(parseFloat(price) || 0, 2)
	//заменяем точку на запятую
	//price_sep   = price_sep.replace(/(\D)/g, ',')
	//добавляем пробел как разделитель в целых
	//price_sep   = price_sep.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
	return new Intl.NumberFormat('ru-RU').format(newPrice)
}

export const sumTotal = function (whole, remains, count) {
	let price_ = ((whole * 100) + remains) * count
	let whole_ = Math.trunc(price_ / 100) || 0
	let remains_ = Math.trunc(price_ % 100) || 0

	return {whole: whole_, remains: remains_}
}