import {ref} from 'vue'
import {defineStore} from 'pinia'
import {useRouter} from 'vue-router'

export const useErrorStore = defineStore('error', () => {
	const data = ref()
	const router = useRouter()

	const push = (error = {}) => {
		data.value = {
			name: error.name || error?.response?.statusText,
			message: error.text || error.message,
			status: error.status || error?.response?.status
		}

		router.push({name: 'error'})
	}

	return {
		data,

		push
	}
})
