import {ref} from 'vue'
import axios from '@/plugins/axios'
import {defineStore} from 'pinia'
import store from 'store2'

export const useAuthStore = defineStore('auth', () => {
	
	const user = ref(null)
	const isAuth = ref(false)
	const showAuthModal = ref(false)

	const info = async () => {
		try {
			const {data} = await axios.get(window.config.url.AUTH_INFO)
			user.value = data
			isAuth.value = true
		}catch(error) {
			
			// eslint-disable-next-line no-console
			console.log(error)
			user.value = null
			isAuth.value = false
		}
	}

	const login = async () => {
		try {
			const {data} = await axios.post(window.config.url.AUTH+'/token', {'refresh-token': store.has('auth') ? store.get('auth').refreshToken : null })
			store.set('auth', {
				accessToken: data.accessToken,
				refreshToken: data.refreshToken
			})           

			info()
		} catch (error) {

			store.remove('auth')
			// eslint-disable-next-line no-console
			console.log('ошибка обновления токена', error)
			throw error
		}
	}

	const logout = () => {
		store.remove('auth')
		user.value = null
		isAuth.value = false
		location.reload()
	}

	const showAuth = () => {
		showAuthModal.value = true
	}
	const hideAuth = () => {
		showAuthModal.value = false
	}

	const toggleAuth = value => {
		showAuthModal.value = value
	}

	return {
		login,
		logout,
		info,
		showAuth,
		hideAuth,
		toggleAuth,
		user,
		isAuth,
		showAuthModal
	}
})
