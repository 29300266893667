<template>
    <QHeader :elevated="false"
             bordered
             class="app-header text-grey-10 q-pa-sm">
        <QToolbar>
            <div class="row container">
                <div class="col-6 col-sm-6 col-md-2 col-lg-2 flex content-center">
                    <div class="lt-md q-mr-sm">
                        <QBtn color="primary"
                              flat
                              round
                              size="sm"
                              @click="toggleSidebar"
                        >
                            <QIcon name="fas fa-bars"
                                   size="24px"/>
                        </QBtn>
                    </div>
                    <div
                        class="cursor-pointer flex content-center"
                        @click="toHome"
                    >
                        <img :alt="infoStore.name"
                             :src="infoStore.logo"
                             class="max-h-3 max-w-7">
                    </div>
                </div>

                <div class="gt-sm col-md-6 col-lg-6 col-xl-7 flex content-center">
                    <div class="header__control full-width relative-position">
                        <div class="row no-wrap full-width">
                            <QBtn class="no-border-radius"
                                  color="primary"
                                  size="md"
                                  style="border-radius: 4px 0 0 4px !important;"
                                  unelevated
                                  @click="toCatalog"
                                  @mouseenter="hoverCatalog"
                            >
                                <span class="flex no-wrap items-center">
                                    <Transition
                                        name="fade">

                                        <QIcon v-if="catalogWidgetModel"
                                               class="q-mr-sm"
                                               name="fas fa-times"
                                               size="xs"
                                               transition-hide="jump-up"
                                               transition-show="jump-down"/>
                                        <QIcon v-else
                                               class="q-mr-sm"
                                               name="fas fa-bars"
                                               size="xs"
                                               transition-hide="jump-up"
                                               transition-show="jump-down"/>
                                    </Transition>

                                    Каталог
                                </span>
                            </QBtn>
                            <div id="headerSearchBar"
                                 ref="searchBar"
                                 class="full-width">
                                <QInput
                                    v-model="searchWidgetText"
                                    class="text-left"
                                    dense
                                    input-class="text-left no-border-radius"
                                    label="Поиск в каталоге и на сайте"
                                    outlined
                                    @focus="searchStore.trigger"
                                    @keydown="searchKeydownHandler"
                                >
                                    <template #append>
                                        <QIcon v-if="searchWidgetShow"
                                               name="fas fa-search"
                                               size="xs"/>

                                        <QIcon v-if="searchWidgetText"
                                               class="cursor-pointer q-ml-sm"
                                               name="fas fa-times"
                                               size="xs"
                                               @click.stop="searchWidgetText = ''"/>
                                    </template>
                                </QInput>
                            </div>
                        </div>

                        <div class="header__search-result q-pt-sm">
                            <Transition name="search">
                                <SearchHeaderWidget v-if="searchWidgetShow"
                                                    :input="searchBar"/>

                            </Transition>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div class="flex full-width full-height align-center justify-end q-pl-xs header__control"
                         style="gap: 14px;">
                        <div v-if="infoStore.mainSiteUrl">
                            <QBtn class="q-pa-sm"
                                  color="white"
                                  flat
                                  no-caps
                                  size="sm"
                                  @click="toMainSite"
                            >
                                <span class="flex column align-center justify-center full-height"
                                      style="gap: 3px;">
                                    <QIcon color="primary"
                                           :name="isMainSiteNow() ? 'fas fa-home' : 'fas fa-solar-panel'"
                                           size="20px"
                                    />
                                    <span class="text-grey-10 gt-sm"
                                          style="line-height: 10px;">{{ isMainSiteNow() ? 'Главная' : 'Сайт' }}</span>
                                </span>
                            </QBtn>
                        </div>
                        <div v-if="authStore.isAuth">
                            <QBtn class="q-pa-xs"
                                  color="white"
                                  flat
                                  no-caps
                                  size="sm"
                                  @click="toOrders"
                            >
                                <span class="flex column align-center justify-center full-height"
                                      style="gap: 3px;">
                                    <QIcon color="primary"
                                           name="fas fa-box-open"
                                           size="20px"
                                    />
                                    <span class="text-grey-10 gt-sm"
                                          style="line-height: 10px;">Заказы</span>
                                </span>
                            </QBtn>
                        </div>

                        <div>
                            <QBtn class="q-pa-xs"
                                  color="white"
                                  flat
                                  no-caps
                                  size="sm"
                                  @click="toFavorite"
                            >
                                <span class="flex column align-center justify-center full-height"
                                      style="gap: 3px;">
                                    <QIcon color="primary"
                                           name="fas fa-heart"
                                           size="20px"
                                    />
                                    <span class="text-grey-10 gt-sm"
                                          style="line-height: 10px;">Избранное</span>
                                </span>
                                <Transition name="fade">
                                    <QBadge v-if="favoriteCount"
                                            color="red"
                                            floating
                                            rounded
                                    >
                                        {{ favoriteCount }}
                                    </QBadge>
                                </Transition>
                            </QBtn>
                        </div>

                        <div>
                            <QBtn class="q-pa-xs"
                                  color="white"
                                  flat
                                  no-caps
                                  size="sm"
                                  @click="toCart"
                            >
                                <span class="flex column align-center justify-center full-height"
                                      style="gap: 3px;">
                                    <QIcon color="primary"
                                           name="fas fa-shopping-cart"
                                           size="20px"
                                    />
                                    <span class="text-grey-10 gt-sm"
                                          style="line-height: 10px;">Корзина</span>
                                </span>

                                <Transition name="fade">
                                    <QBadge v-if="cartCount"
                                            color="red"
                                            floating
                                            rounded
                                    >
                                        {{ cartCount }}
                                    </QBadge>
                                </Transition>
                            </QBtn>
                        </div>

                        <div class="header__auth gt-sm">
                            <div v-if="authStore.isAuth && !infoStore.inIframe"
                                 class="cursor-pointer q-ml-sm"
                            >
                                <div class="header__user-avatar">
                                    <Avatar :name="`${authStore.user.surname} ${authStore.user.name}`"
                                            box-shadow="0 0 0 2px white, 0 0 0 4px var(--q-primary)"/>
                                </div>
                                <QMenu
                                    :offset="[8, 8]"
                                    anchor="top right"
                                    self="top right"
                                    transition-hide="fade"
                                    transition-show="fade"
                                >
                                    <div class="flex column q-pa-sm"
                                         style="width: 324px;">
                                        <QBtn
                                            v-close-popup
                                            :href="appUrl"
                                            align="left"
                                            class="q-px-md q-py-sm"
                                            color="dark"
                                            flat
                                            no-caps
                                        >
                                            <div class="flex no-wrap">
                                                <div style="width: 48px;">
                                                    <Avatar
                                                        :name="`${authStore.user.surname} ${authStore.user.name}`"
                                                        box-shadow="0 0 0 2px white, 0 0 0 4px var(--q-primary)"
                                                        fontSize="1.25rem"
                                                        size="48px"
                                                    />
                                                </div>

                                                <div class="q-ml-md">
                                                    <div class="flex column text-left">
                                                        <span class="text-subtitle1 text-weight-bold">{{
                                                            `${authStore.user.surname} ${authStore.user.name}`
                                                        }}</span>
                                                        <span class="text-subtitle2 text-grey-6">Личный кабинет</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </QBtn>

                                        <QBtn
                                            v-if="authStore.isAuth"
                                            v-close-popup
                                            align="left"
                                            class="q-px-md q-py-sm"
                                            color="dark"
                                            flat
                                            no-caps
                                            @click="toOrders"
                                        >
                                            <div class="flex no-wrap">
                                                <div class="flex justify-end align-center"
                                                     style="width: 48px;">
                                                    <QIcon
                                                        class="q-mr-sm"
                                                        name="fas fa-box-open"
                                                        size="20px"/>
                                                </div>

                                                <div class="q-ml-md">
                                                    <span class="text-subtitle1">Заказы</span>
                                                </div>
                                            </div>
                                        </QBtn>

                                        <QBtn
                                            v-close-popup
                                            align="left"
                                            class="q-px-md q-py-sm"
                                            color="dark"
                                            flat
                                            no-caps
                                            @click="toCart"
                                        >
                                            <div class="flex no-wrap">
                                                <div class="flex justify-end align-center"
                                                     style="width: 48px;">
                                                    <QIcon
                                                        class="q-mr-sm"
                                                        name="fas fa-shopping-cart"
                                                        size="20px"/>
                                                </div>

                                                <div class="q-ml-md">
                                                    <span class="text-subtitle1">Корзина
                                                        <b class="text-grey-5 text-weight-thin q-ml-xs">{{
                                                            cartCount
                                                        }}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </QBtn>

                                        <QBtn
                                            v-close-popup
                                            align="left"
                                            class="q-px-md q-py-sm"
                                            color="dark"
                                            flat
                                            no-caps
                                            @click="toFavorite"
                                        >
                                            <div class="flex no-wrap">
                                                <div class="flex justify-end align-center"
                                                     style="width: 48px;">
                                                    <QIcon
                                                        class="q-mr-sm"
                                                        name="fas fa-heart"
                                                        size="20px"/>
                                                </div>

                                                <div class="q-ml-md">
                                                    <span class="text-subtitle1">Избранное
                                                        <b class="text-grey-5 text-weight-thin q-ml-xs">{{
                                                            favoriteCount
                                                        }}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </QBtn>

                                        <QBtn
                                            v-close-popup
                                            align="left"
                                            class="q-px-md q-py-sm"
                                            color="dark"
                                            flat
                                            no-caps
                                            @click="logout"
                                        >
                                            <div class="flex no-wrap">
                                                <div class="flex justify-end align-center"
                                                     style="width: 48px;">
                                                    <QIcon
                                                        class="q-mr-sm"
                                                        name="fas fa-door-open"
                                                        size="20px"/>
                                                </div>

                                                <div class="q-ml-md">
                                                    <span class="text-subtitle1">Выйти</span>
                                                </div>
                                            </div>
                                        </QBtn>
                                    </div>
                                </QMenu>
                            </div>
                            <div v-else-if="!infoStore.inIframe">
                                <QBtn
                                    class="q-pa-xs"
                                    color="white"
                                    flat
                                    no-caps
                                    size="sm"
                                    @click="authStore.showAuth()"
                                >
                                    <span class="flex column align-center justify-center full-height"
                                          style="gap: 3px;">
                                        <QIcon color="primary"
                                               name="fas fa-user"
                                               size="20px"
                                        />
                                        <span class="text-grey-10 gt-sm"
                                              style="line-height: 10px;">Войти</span>
                                    </span>
                                </QBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </QToolbar>


        <div class="container">
            <CatalogHeaderWidget/>
        </div>
    </QHeader>
</template>

<script>
import CatalogHeaderWidget from '@/components/catalog/CatalogHeaderWidget'
import SearchHeaderWidget from '@/components/search/SearchHeaderWidget.vue'
import Avatar from '@/components/Avatar'
import {useRouter} from 'vue-router'
import {computed, ref} from 'vue'

import {useInfoStore} from '@/stores/info'
import {useAuthStore} from '@/stores/auth'
import {useCatalogStore} from '@/stores/catalog'
import {useSearchStore} from '@/stores/search'
import {useFavoriteStore} from '@/stores/favorite'
import {useCartStore} from '@/stores/cart'
import {useSidebarStore} from '@/stores/sidebar'

import {useQuasar} from 'quasar'

export default {
	name: 'AppHeader',
	components: {
		CatalogHeaderWidget,
		SearchHeaderWidget,
		Avatar
	},
	props: {
		showAuth: Boolean
	},

	setup(props, {emit}) {
		const $q = useQuasar()

		const router = useRouter()
		const authStore = useAuthStore()
		const infoStore = useInfoStore()
		const catalogStore = useCatalogStore()
		const searchStore = useSearchStore()

		const searchBar = ref(null)

		const searchWidgetText = computed({
			get() {
				return searchStore.model
			},

			set(value) {
				searchStore.set(value)
			}
		})

		const searchWidgetShow = computed({
			get() {
				return searchStore.show
			},

			set(value) {
				searchStore.toggleShow(value)
			}
		})

		const catalogWidgetModel = computed({
			get() {
				return catalogStore.headerWidgetModel
			},

			set(value) {
				catalogStore.toggleHeaderWidgetModel(value)
			}
		})


		const toCatalog = () => {
			router.push({path: '/catalog'})
			catalogWidgetModel.value = false
		}

		const hoverCatalog = () => {
			if (!searchStore.show) {
				catalogWidgetModel.value = true
			}
		}

		const favoriteStore = useFavoriteStore()
		const favoriteCount = computed(() => {
			return favoriteStore.list.length
		})

		const toFavorite = () => {
			router.push({name: 'favorite'})
		}

		const isMainSiteNow = () => {
			let urlData1 = new URL(window.location.href)
			let urlData2 = new URL(infoStore.mainSiteUrl)
			return !urlData2 || urlData1.host === urlData2.host
		}

		const cartStore = useCartStore()
		const cartCount = computed(() => {
			return cartStore.products.length
		})

		const toCart = () => {
			router.push({name: 'cart'})
		}

		const setIsAuth = () => {
			emit('set-is-auth')
		}

		const toHome = () => {
			router.push({name: 'home'})
		}

		const toOrders = () => {
			if (infoStore.inIframe) {
				window.top.postMessage('orders', '*')
			} else {
				window.top.location.replace(window.config.url.APP + '/orders')
			}
		}

		const toMainSite = () => {
			if (isMainSiteNow()) {
				router.push({name: 'home'})
			} else {
				window.top.location.replace(infoStore.mainSiteUrl)
			}
		}

		const searchKeydownHandler = event => {
			if (event.keyCode === 13) {
				router.push({name: 'search', query: {q: searchStore.q}})
				searchStore.toggleShow(false)
			}
		}

		const appUrl = window.config.url.APP

		const sidebarStore = useSidebarStore()
		const toggleSidebar = () => {
			sidebarStore.toggle()
		}

		const logout = () => {
			$q.dialog({
				title: 'Подтвердите действие',
				message: 'Вы действительно хотите выйти?'
			}).onOk(() => {
				authStore.logout()
			})
		}

		return {
			infoStore,
			searchStore,
			authStore,
			catalogWidgetModel,

			searchBar,
			searchWidgetText,
			searchWidgetShow,

			toCatalog,
			hoverCatalog,

			favoriteCount,
			toFavorite,

			cartCount,
			toCart,

			setIsAuth,
			toMainSite,
			toHome,
			toOrders,
			isMainSiteNow,
			searchKeydownHandler,
			appUrl,

			toggleSidebar,

			logout
		}
	}
}
</script>

<style lang="scss" scoped>
.app-header {
  background-color: white;
  border: none;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 6%);
}

#headerSearchBar:deep(.q-field__control) {
  border-radius: 0 4px 4px 0 !important;
}

#headerSearchBar:deep(.q-field--outlined .q-field__control:before) {
  border: 2px solid var(--q-primary);
}

.header__search-result {
  position: absolute;
  width: 100%;
}

.header__control:deep(.q-badge) {
  box-shadow: 0 0 0 2px white;
}

.fade-enter-active,
.fade-leave-active {
  transition: .35s all;
}

.fade-enter-from,
.fade-leave-to {
  transform: scale(0);
}

.fade-leave-active {
  position: absolute;
}

.search-enter-active,
.search-leave-active {
  transition: .35s ease all;
}

.search-enter-from,
.search-leave-to {
  transform: translate(0, 50px) scale(0.95);
  opacity: 0;
}
</style>
